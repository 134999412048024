import { PostCreateUserData } from "../api-invoker/post-create-user";
import { PostConnectPayPal } from "../api-invoker/post-connect-paypal";
import { GetTotalRevenueProtected } from "../api-invoker/get-total-revenue-protected";
import { GetLinkedShops } from "../api-invoker/get-linked-shops";
import { DeleteLinkedShops } from "../api-invoker/delete-linked-shops";
import { GetLinkedPayPal } from "../api-invoker/get-linked-payPal";
import { GetRecentDisputes } from "../api-invoker/get-recent-disputes";
import { GetOpenDisputes } from "../api-invoker/get-open-disputes";
import { GetRecoveredDisputes } from "../api-invoker/get-recovered-disputes";
import { GetCurrentWeekDisputes } from "../api-invoker/get-current-week-disputes";
import { GetCurrentMonthDisputes } from "../api-invoker/get-current-month-disputes";
import { GetDisputesList } from "../api-invoker/get-disputes-list";
import { PostFilteredDisputes } from "../api-invoker/post-filtered-disputes";
import { GetProcessingDisputes } from "../api-invoker/get-processing-disputes";
import { GetAwaitingResponseDisputes } from "../api-invoker/get-awaiting-response-disputes";
import { GetDisputeDetails } from "../api-invoker/get-dispute-details";
import { GetDisputeTransactionDetails } from "../api-invoker/get-dispute-transaction-details";
import { GetDisputeEvidenceDetails } from "../api-invoker/get-dispute-evidence-details";
import { GetDisputeMessageDetails } from "../api-invoker/get-dispute-message-details";
import { GetDisputeAdjudicationDetails } from "../api-invoker/get-dispute-adjudication-details";
import { GetDisputeMoneyMovementDetails } from "../api-invoker/get-dispute-money-movement-details";
import { GetDisputeSupportingInfoDetails } from "../api-invoker/get-dispute-supporting-info-details";
import { GetDisputeOfferDetails } from "../api-invoker/get-dispute-offer-details";
//shop
import { PostAddShopifyShop } from "../api-invoker/post-add-shopify-shop";
//shopify-controller
import { ShopifyController } from "../api-invoker/shopify-controller";
//notifications
import { GetNotificationsList } from "../api-invoker/get-notifications-list";
//BrianTree
import { PostBrainTreeSettings } from "../api-invoker/post-brainTree-settings";
//ListPSP
import { GetListPspList } from "../api-invoker/get-list-psp-list";
//stripe-integartion
import { PostStripeLogin } from "../api-invoker/post-stripe-login";
import {PostStripeOnboard} from "../api-invoker/post-stripe-onboard"
import {PostStripeUpdateBusinessProfile} from "../api-invoker/post-stripe-updateProfile"
import { PostKlarnaSettings } from '../api-invoker/post-klarna-settings';
import {PostDisputeFlow} from "../api-invoker/post-dispute-flow"

export class Api {

    static async postCreateUserData(payload: any) {
        return new PostCreateUserData().invoke(payload)
    }

    //paypal
    static async postConnectPayPal(payload: any) {
        return new PostConnectPayPal().invoke(payload)
    }

    static async getConnectedPayPalList(payload: any) {
        return new PostConnectPayPal().invokeConnectedPayPalList(payload)
    }

    static async deletePayPal(payload: number) {
        return new PostConnectPayPal().invokeDeletePayPal(payload)
    }


    static async getTotalRevenueProtected() {
        return new GetTotalRevenueProtected().invoke()
    }

    static async getLinkedShops() {
        return new GetLinkedShops().invoke()
    }

    static async deleteLinkedShops(payload: number) {
        return new DeleteLinkedShops().invoke(payload)
    }

    static async getLinkedPayPal() {
        return new GetLinkedPayPal().invoke()
    }

    static async getRecentDisputes() {
        return new GetRecentDisputes().invoke()
    }

    static async getOpenDisputes() {
        return new GetOpenDisputes().invoke()
    }

    static async getRecoveredDisputes() {
        return new GetRecoveredDisputes().invoke()
    }

    static async getCurrentWeekDisputes() {
        return new GetCurrentWeekDisputes().invoke()
    }

    static async getCurrentMonthDisputes() {
        return new GetCurrentMonthDisputes().invoke()
    }

    static async getDisputesList(payload: any) {
        return new GetDisputesList().invoke(payload)
    }

    static async postFilteredDisputes(payload: any) {
        return new PostFilteredDisputes().invoke(payload)
    }

    static async getProcessingDisputes() {
        return new GetProcessingDisputes().invoke()
    }

    static async getAwaitingResponseDisputes() {
        return new GetAwaitingResponseDisputes().invoke()
    }

    //dispute-details
    static async getDisputeDetails(payload: any) {
        return new GetDisputeDetails().invoke(payload)
    }

    static async getDisputeTransactionDetails(payload: any) {
        return new GetDisputeTransactionDetails().invoke(payload)
    }

    static async getDisputeEvidenceDetails(payload: any) {
        return new GetDisputeEvidenceDetails().invoke(payload)
    }

    static async getDisputeMessageDetails(payload: any) {
        return new GetDisputeMessageDetails().invoke(payload)
    }

    static async getDisputeAdjudicationDetails(payload: any) {
        return new GetDisputeAdjudicationDetails().invoke(payload)
    }

    static async getDisputeMoneyMovementDetails(payload: any) {
        return new GetDisputeMoneyMovementDetails().invoke(payload)
    }

    static async getDisputeSupportingInfoDetails(payload: any) {
        return new GetDisputeSupportingInfoDetails().invoke(payload)
    }

    static async getDisputeOfferDetails(payload: any) {
        return new GetDisputeOfferDetails().invoke(payload)
    }

    static async getDisputeOrderSummary(payload: any) {
        return new GetDisputeOfferDetails().invokeDisputeOrderSummary(payload)
    }

    static async getManageDisputeFraudAnalysisDetails(payload: any) {
        return new GetDisputeOfferDetails().invokeByFraudAnalysisDetails(payload)
    }

    static async getManageDisputeByCustomerDetails(payload: any) {
        return new GetDisputeOfferDetails().invokeByDisputeCustomerInformation(payload)
    }

    static async getManageDisputeOrderFullmentDetails(payload: any) {
        return new GetDisputeOfferDetails().invokeByManageDisputeFullfillment(payload)
    }

    static async getManageDisputeGetTrackingDetails(payload: any) {
        return new GetDisputeOfferDetails().invokeByManageDisputeTrackingDetails(payload)
    }

    static async postDisputeSupportingEvidence(payload: any) {
        return new GetDisputeOfferDetails().invokeAddSupportingEvidence(payload)
    }

    //shop
    static async postAddShopifyShop(payload: any) {
        return new PostAddShopifyShop().invoke(payload)
    }

    static async getShopsNotifications() {
        return new PostAddShopifyShop().invokeShopsNotifications()
    }

    static async postShopsSaveNotifications(payload: any) {
        return new PostAddShopifyShop().invokeShopsSaveNotifications(payload)
    }

    static async postShopsAddOnboarded() {
        return new PostAddShopifyShop().invokeShopsAddOnboarded()
    }

    static async postShopsAddRisk() {
        return new PostAddShopifyShop().invokeShopsAddRisk()
    }

    static async getShopsData() {
        return new PostAddShopifyShop().invokeShopsData()
    }

    //shopify-controller
    static async getShopifyHmac(payload: any) {
        return new ShopifyController().invoke(payload)
    }

    static async postShopifyCreateCharge() {
        return new ShopifyController().invokeShopifyCreateCharge()
    }

    static async postShopifyCreateSubscription() {
        return new ShopifyController().invokeShopifyCreateSubscription()
    }

    static async postShopifyActivateCharge(payload: any) {
        return new ShopifyController().invokeShopifyActivateCharge(payload)
    }

    //notifications
    static async getNotificationsList() {
        return new GetNotificationsList().invoke()
    }

    //BrianTree
    static async postBrainTreeSettings(payload: any) {
        return new PostBrainTreeSettings().invoke(payload)
    }

    static async getBrainTreeList(payload: any) {
        return new PostBrainTreeSettings().invokeBrainTreeList(payload)
    }

    //Klarna
    static async postKlarnaSettings(payload: any) {
        return new PostKlarnaSettings().invoke(payload)
    }

    static async getKlarnaList(payload: any) {
        return new PostKlarnaSettings().invokeKlarnaList(payload)
    }

    //List-PSP
    static async getListPspList() {
        return new GetListPspList().invoke()
    }

    //stripe-integartion
    static async postStripeLogin(payload: any) {
        return new PostStripeLogin().invoke(payload)
    }

    // stripe-integartion
    static async PostStripeOnboard(payload: any) {
        return new PostStripeOnboard().invoke(payload)
    }

    // stripe-update-business-profile
    static async PostStripeUpdateBusiness(payload: any) {
        return new PostStripeUpdateBusinessProfile().invoke(payload)
    }

    // handle dispute response
    static async postHandledByDisputeRequest(payload:any){
        return new PostDisputeFlow().invokeHandleDispute(payload)
    }

}