import React, { createContext, useContext, useState, ReactNode } from 'react';

type DisputeToggleContextType = {
  toggleState: Record<string, boolean>;
  setToggleState: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
};

const DisputeToggleContext = createContext<DisputeToggleContextType | undefined>(undefined);

type DisputeToggleProviderProps = {
  children: ReactNode;
};

export const DisputeToggleProvider = ({ children }: DisputeToggleProviderProps) => {
  const [toggleState, setToggleState] = useState<Record<string, boolean>>({});

  return (
    <DisputeToggleContext.Provider value={{ toggleState, setToggleState }}>
      {children}
    </DisputeToggleContext.Provider>
  );
};

// Custom hook to use the context
export const useDisputeToggle = () => {
  const context = useContext(DisputeToggleContext);
  if (!context) {
    throw new Error('useDisputeToggle must be used within a DisputeToggleProvider');
  }
  return context;
};
