import { takeEvery, all } from "redux-saga/effects";
import * as constants from "./constants";
import { postCreateUserDataRequest } from './auth/actions';
import {
  postConnectPayPalRequest,
  getConnectedPayPalListRequest,
  deletePayPalRequest
} from './connectPayPal/action';
import {
  getTotalRevenueProtectedDataRequest,
  getLinkedShopsRequest,
  getLinkedPayPalRequest,
  getRecentDisputesDataRequest,
  getOpenDisputesDataRequest,
  getRecoveredDisputesDataRequest,
  getCurrentWeekDisputesDataRequest,
  getCurrentMonthDisputesDataRequest,
  deleteLinkedShopsRequest,
} from './dashboard/actions';
import {
  getDisputesListDataRequest,
  postFilteredDisputesDataRequest,
  postHandledByDisputeRequest,
  getProcessingDisputesDataRequest,
  getAwaitingResponseDisputesDataRequest,
} from "./disputesList/actions";
import {
  getDisputeDetailsRequest,
  getDisputeTransactionDetailsRequest,
  getDisputeEvidenceDetailsRequest,
  getDisputeMessageDetailsRequest,
  getDisputeAdjudicationDetailsRequest,
  getDisputeMoneyMovementDetailsRequest,
  getDisputeSupportingInfoDetailsRequest,
  getDisputeOfferDetailsRequest,
  getDisputeOrderSummaryRequest,
  getDisputeFraudAnalysisRequest,
  getDisputeCustomerDetailsRequest,
  getDisputeOrderFulfillmentDetailsRequest,
  getDisputeTrackingDetailsRequest
} from "./disputeDetails/actions";
//shops
import {
  postAddShopifyShopRequest,
  getShopsNotificationsRequest,
  postShopsSaveNotificationsRequest,
  postShopsAddOnboardedRequest,
  postShopsAddRiskRequest,
  getShopsDataRequest
} from "./shop/actions";
//shopify-controller
import {
  getShopifyHmacRequest,
  postShopifyCreateChargeRequest,
  postShopifyCreateSubscriptionRequest,
  postShopifyActivateChargeRequest
} from "./shopifyController/actions";
//notifications
import {
  getNotificationsListRequest
} from "./notifications/actions";
//BrianTree
import {
  postBrainTreeSettingsRequest,
  getBrainTreeListRequest
} from "./brainTree/actions";

import {
  getListPspListRequest
} from "./listPSP/actions";

//stripe-integartion
import {
  postStripeLoginRequest,
  postStripeOnboardLoginRequest,
  postStripeUpdateBusinessProfileRequest
} from "./stripe/actions";
import { postKlarnaSettingsRequest,getKlarnaListRequest } from './klarna/actions';

export function* watchActions() {
  yield takeEvery(constants.POST_CREATE_USER_REQUEST, postCreateUserDataRequest);

  //paypal
  yield takeEvery(constants.POST_CONNECT_PAYPAL_REQUEST, postConnectPayPalRequest);
  yield takeEvery(constants.GET_CONNECTED_PAYPAL_LIST_REQUEST, getConnectedPayPalListRequest);
  yield takeEvery(constants.DELETE_PAYPAL_REQUEST, deletePayPalRequest);

  yield takeEvery(constants.GET_TOTAL_REVENUE_PROTECTED_REQUEST, getTotalRevenueProtectedDataRequest);
  yield takeEvery(constants.GET_LINKED_SHOPS_REQUEST, getLinkedShopsRequest);
  yield takeEvery(constants.DELETE_LINKED_SHOPS_REQUEST, deleteLinkedShopsRequest);
  yield takeEvery(constants.GET_LINKED_PAYPAL_REQUEST, getLinkedPayPalRequest);
  yield takeEvery(constants.GET_RECENT_DISPUTES_REQUEST, getRecentDisputesDataRequest);
  yield takeEvery(constants.GET_OPEN_DISPUTES_REQUEST, getOpenDisputesDataRequest);
  yield takeEvery(constants.GET_RECOVERED_DISPUTES_REQUEST, getRecoveredDisputesDataRequest);
  yield takeEvery(constants.GET_CURRENT_WEEK_DISPUTES_REQUEST, getCurrentWeekDisputesDataRequest);
  yield takeEvery(constants.GET_CURRENT_MONTH_DISPUTES_REQUEST, getCurrentMonthDisputesDataRequest);
  yield takeEvery(constants.GET_DISPUTES_LIST_REQUEST, getDisputesListDataRequest);
  yield takeEvery(constants.POST_FILTERED_DISPUTES_REQUEST, postFilteredDisputesDataRequest);
  yield takeEvery(constants.POST_HANDLED_BY_DISPUTE_REQUEST, postHandledByDisputeRequest);
  yield takeEvery(constants.GET_PROCESSING_DISPUTES_REQUEST, getProcessingDisputesDataRequest);
  yield takeEvery(constants.GET_AWAITING_RESPONSE_DISPUTES_REQUEST, getAwaitingResponseDisputesDataRequest);

  //dispute-details
  yield takeEvery(constants.GET_DISPUTE_DETAILS_REQUEST, getDisputeDetailsRequest);
  yield takeEvery(constants.GET_DISPUTE_TRANSACTION_DETAILS_REQUEST, getDisputeTransactionDetailsRequest);
  yield takeEvery(constants.GET_DISPUTE_EVIDENCE_DETAILS_REQUEST, getDisputeEvidenceDetailsRequest);
  yield takeEvery(constants.GET_DISPUTE_MESSAGE_DETAILS_REQUEST, getDisputeMessageDetailsRequest);
  yield takeEvery(constants.GET_DISPUTE_ADJUDICATION_DETAILS_REQUEST, getDisputeAdjudicationDetailsRequest);
  yield takeEvery(constants.GET_DISPUTE_MONEY_MOVEMENT_DETAILS_REQUEST, getDisputeMoneyMovementDetailsRequest);
  yield takeEvery(constants.GET_DISPUTE_SUPPORTING_INFO_DETAILS_REQUEST, getDisputeSupportingInfoDetailsRequest);
  yield takeEvery(constants.GET_DISPUTE_OFFER_DETAILS_REQUEST, getDisputeOfferDetailsRequest);
  yield takeEvery(constants.GET_DISPUTE_ORDER_SUMMARY_REQUEST, getDisputeOrderSummaryRequest);
  yield takeEvery(constants.GET_DISPUTE_FRAUD_ANALYSIS_DETAILS_REQUEST, getDisputeFraudAnalysisRequest);
  yield takeEvery(constants.GET_DISPUTE_CUSTOMER_DETAILS_REQUEST, getDisputeCustomerDetailsRequest);
  yield takeEvery(constants.GET_DISPUTE_ORDER_FULFILLMENT_DETAILS_REQUEST, getDisputeOrderFulfillmentDetailsRequest);
  yield takeEvery(constants.GET_DISPUTE_TRACKING_DETAILS_REQUEST, getDisputeTrackingDetailsRequest );

  //shops
  yield takeEvery(constants.POST_ADD_SHOPIFY_SHOP_REQUEST, postAddShopifyShopRequest);
  yield takeEvery(constants.GET_SHOPS_NOTIFICATIONS_REQUEST, getShopsNotificationsRequest);
  yield takeEvery(constants.POST_SHOPS_SAVE_NOTIFICATIONS_REQUEST, postShopsSaveNotificationsRequest);
  yield takeEvery(constants.POST_SHOPS_ADD_ONBOARDED_REQUEST, postShopsAddOnboardedRequest);
  yield takeEvery(constants.POST_SHOPS_ADD_RISK_REQUEST, postShopsAddRiskRequest);
  yield takeEvery(constants.GET_SHOPS_DATA_REQUEST, getShopsDataRequest);

  //shopify-controller
  yield takeEvery(constants.GET_SHOPIFY_HMAC_REQUEST, getShopifyHmacRequest);
  yield takeEvery(constants.POST_SHOPIFY_CREATE_CHARGE_REQUEST, postShopifyCreateChargeRequest);
  yield takeEvery(constants.POST_SHOPIFY_CREATE_SUBSCRIPTION_REQUEST, postShopifyCreateSubscriptionRequest);
  yield takeEvery(constants.POST_SHOPIFY_ACTIVATE_CHARGE_REQUEST, postShopifyActivateChargeRequest);

  //notification
  yield takeEvery(constants.GET_NOTIFICATIONS_LIST_REQUEST, getNotificationsListRequest);

  //BrianTree
  yield takeEvery(constants.POST_BRAINTREE_SETTINGS_REQUEST, postBrainTreeSettingsRequest);
  yield takeEvery(constants.GET_BRAINTREE_LIST_REQUEST, getBrainTreeListRequest);

  //Klarna
  yield takeEvery(constants.POST_KLARNA_SETTINGS_REQUEST, postKlarnaSettingsRequest);

  //List-PSP
  yield takeEvery(constants.GET_LIST_PSP_LIST_REQUEST, getListPspListRequest);

  //stripe-integartion
  yield takeEvery(constants.POST_STRIPE_LOGIN_REQUEST, postStripeLoginRequest);

  //stripe-onload-integartion
  yield takeEvery(constants.POST_STRIPE_ONBOARD_LOGIN_REQUEST, postStripeOnboardLoginRequest);

  //stripe-update-business-profile
  yield takeEvery(constants.POST_STRIPE_UPDATE_BUSINESS_PROFILE_REQUEST, postStripeUpdateBusinessProfileRequest);

}

export default function* rootSaga() {
  yield all([watchActions()]);
}