import { Link } from 'react-router-dom';
import styles from './style.module.scss';
import { svgIcons } from 'elements';
import Banner from "../../assets/banner.png"

const NewLogin = () => {
  return (
    <div className={styles.loginContainer}>
      <div className={styles.loginLeft}>
        <form>
          <Link to='/home'>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                // justifyContent: 'center',
                margin:"20px 0"
              }}
            >
              <img
                className={styles.logoIcon}
                src={svgIcons.ChargePayLogo}
                alt='tp'
              />
              <img
                className={styles.logoName}
                src={svgIcons.ChargePayName}
                alt='ChargePay'
              />
            </div>
          </Link>
          <h2>Sign In</h2>
          <label>Email</label>
          <input type='email' placeholder='Enter your email' required />
          <label>Password</label>
          <input type='password' placeholder='Enter your password' required />
          <div className={styles.options}>
            <label>
              <input type='checkbox' /> Remember Me
            </label>
            <Link to='#'>Forgot Password?</Link>
          </div>
          <button type='submit' className={styles.signInBtn}>
            Sign In
          </button>
        </form>
        <p>or sign in with</p>
        <div className={styles.authOptions}>
          <button className={styles.shopifyBtn}>
            <img src={svgIcons.Stripe} alt='' /> Shopify
          </button>
          <button className={styles.stripeBtn}>
            <img src={svgIcons.Stripe} alt='' /> Stripe
          </button>
        </div>
        <p>
          Don’t have an account? <Link to='#' style={{borderBottom:"1px solid #000"}}>Sign Up</Link>
        </p>
      </div>
      <div className={styles.loginRight}>
        <img
          src={Banner}
          alt='Dashboard'
          className={styles.dashboardImage}
        />
        <h3>WIN CHARGEBACK ON AUTOPILOT</h3>
        <p>Reclaim More Revenue</p>
      </div>
    </div>
  );
};

export default NewLogin;
