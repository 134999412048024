import * as actions from "../actions";
import { put } from "redux-saga/effects";
import { Api } from "../../services/accountApi/network";

interface DisputeObj {
    "id": string,
    "createTime": string,
    "updateTime": string,
    "status": string,
    "reason": string,
    "disputeState": string,
    "disputeLifeCycleStage": string,
    "order": string,
    "storeOrderId": string,
    "shopName": string,
    "shopType": string,
    "sellerMerchantId": string,
    "disputeAmountValue": number,
    "disputeAmountCurrencyCode": string,
    "sellerResponseDueDate": string,
    "buyerResponseDueDate": string,
    "sellerTransactionId": string,
    "paypalId": string
}

interface ResProp {
    data: {
        count: number,
        disputeResponseList: DisputeObj[],
    },
    status:number
}
interface ResPropProcessDispute {
    data: {
        "totalChallengedAmount": number,
        "monthlyDisputeDetails": [
            {
                "day": number,
                "count": number
            },
        ],
        "prevMonthComparison": number
    },
    status:number
}

interface disputeFlowRes {
    data: {
        "id": number,
        "content": string,
        "type": string,
        "description": string,
        "enabled": boolean,
        "category": string,
        "paymentGateway": string
    }
    status:number;
}


export function* getDisputesListDataRequest(action: any) {
    try {
        const res: ResProp = yield Api.getDisputesList(action.payload);
        if(res.status === (500 || 401)){
            yield put(actions.getDisputesListError());
        }else{
            yield put(actions.getDisputesListSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getDisputesListError());
    }
}

export function* postFilteredDisputesDataRequest(action: any) {
    try {
        const res: ResProp = yield Api.postFilteredDisputes(action.payload);
        yield put(actions.postFilteredDisputesSuccess(res.data));
    } catch (e) {
        yield put(actions.postFilteredDisputesError());
    }
}

export function* getProcessingDisputesDataRequest() {
    try {
        const res: ResPropProcessDispute = yield Api.getProcessingDisputes();
        if(res.status === (500 || 401)){
            yield put(actions.getProcessingDisputesError());
        }else{
            yield put(actions.getProcessingDisputesSuccess(res.data));
        }

    } catch (e) {
        yield put(actions.getProcessingDisputesError());
    }
}

export function* getAwaitingResponseDisputesDataRequest() {
    try {
        const res: ResPropProcessDispute = yield Api.getAwaitingResponseDisputes();
        if(res.status === (500 || 401)){
            yield put(actions.getAwaitingResponseDisputesError());
        }else{
            yield put(actions.getAwaitingResponseDisputesSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getAwaitingResponseDisputesError());
    }
}

// action for handled dispute response
export function* postHandledByDisputeRequest(action: any) {
    try {
        const res: disputeFlowRes = yield Api.postHandledByDisputeRequest(action.payload);
        if(res.status === (500 || 401)){
            yield put(actions.postHandledByDisputeError())
        }
        yield put(actions.postHandledByDisputeSuccess(res.data));
    } catch (e) {
        yield put(actions.postHandledByDisputeError());
    }
}
