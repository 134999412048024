import ApiService from 'services/apiService';
import { apiConstants } from '../network';
import { configs } from 'configs';

export class PostDisputeFlow {
  async invokeHandleDispute(payload: any) {
    const res = await ApiService.getInstance().post(
      apiConstants.POST_DISPUTE_HANDLE_SAVE,
      payload,
      { baseURL: configs.BASE_URL as string }
    );

    return res;
  }
}
