import * as React from "react";

export interface SvgIconProps extends React.SVGProps<SVGSVGElement> {
  color?: string;
}

const ArrowRight: React.FC<SvgIconProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M6 11.9941L10 7.99414L6 3.99414"
      stroke="#8391A2"
      strokeWidth={1.33333}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Eye: React.FC<SvgIconProps> = (props) => (
  <svg
    width={12}
    height={12}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_33617_28938)">
      <path
        d="M0.5 6C0.5 6 2.5 2 6 2C9.5 2 11.5 6 11.5 6C11.5 6 9.5 10 6 10C2.5 10 0.5 6 0.5 6Z"
        stroke="#A7BEEA"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 7.5C6.82843 7.5 7.5 6.82843 7.5 6C7.5 5.17157 6.82843 4.5 6 4.5C5.17157 4.5 4.5 5.17157 4.5 6C4.5 6.82843 5.17157 7.5 6 7.5Z"
        stroke="#A7BEEA"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_33617_28938">
        <rect width={12} height={12} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const BlueEye: React.FC<SvgIconProps> = (props) => (
  <svg
    width={19}
    height={13}
    viewBox="0 0 19 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={18.6875} height={13} rx={6.5} fill="#225CCB" />
    <g clipPath="url(#clip0_6216_235708)">
      <path
        d="M4.875 6.5C4.875 6.5 6.5 3.25 9.34375 3.25C12.1875 3.25 13.8125 6.5 13.8125 6.5C13.8125 6.5 12.1875 9.75 9.34375 9.75C6.5 9.75 4.875 6.5 4.875 6.5Z"
        stroke="#A7BEEA"
        strokeWidth={1.21875}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.34375 7.71875C10.0168 7.71875 10.5625 7.1731 10.5625 6.5C10.5625 5.8269 10.0168 5.28125 9.34375 5.28125C8.67065 5.28125 8.125 5.8269 8.125 6.5C8.125 7.1731 8.67065 7.71875 9.34375 7.71875Z"
        stroke="#A7BEEA"
        strokeWidth={1.21875}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6216_235708">
        <rect
          width={9.75}
          height={9.75}
          fill="white"
          transform="translate(4.46875 1.625)"
        />
      </clipPath>
    </defs>
  </svg>
);

const LayersThree: React.FC<SvgIconProps> = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 12.0001L11.6422 16.8212C11.7734 16.8868 11.839 16.9196 11.9078 16.9325C11.9687 16.9439 12.0313 16.9439 12.0922 16.9325C12.161 16.9196 12.2266 16.8868 12.3578 16.8212L22 12.0001M2 17.0001L11.6422 21.8212C11.7734 21.8868 11.839 21.9196 11.9078 21.9325C11.9687 21.9439 12.0313 21.9439 12.0922 21.9325C12.161 21.9196 12.2266 21.8868 12.3578 21.8212L22 17.0001M2 7.00006L11.6422 2.17895C11.7734 2.11336 11.839 2.08056 11.9078 2.06766C11.9687 2.05622 12.0313 2.05622 12.0922 2.06766C12.161 2.08056 12.2266 2.11336 12.3578 2.17895L22 7.00006L12.3578 11.8212C12.2266 11.8868 12.161 11.9196 12.0922 11.9325C12.0313 11.9439 11.9687 11.9439 11.9078 11.9325C11.839 11.9196 11.7734 11.8868 11.6422 11.8212L2 7.00006Z"
      stroke="#2057C5"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const Activity: React.FC<SvgIconProps> = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22 12H18L15 21L9 3L6 12H2"
      stroke="#2057C5"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const CurrencyDollar: React.FC<SvgIconProps> = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 16C6 18.2091 7.79086 20 10 20H14C16.2091 20 18 18.2091 18 16C18 13.7909 16.2091 12 14 12H10C7.79086 12 6 10.2091 6 8C6 5.79086 7.79086 4 10 4H14C16.2091 4 18 5.79086 18 8M12 2V22"
      stroke="#225CCB"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const Trophy: React.FC<SvgIconProps> = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 15C8.68629 15 6 12.3137 6 9V3.44444C6 3.0306 6 2.82367 6.06031 2.65798C6.16141 2.38021 6.38021 2.16141 6.65798 2.06031C6.82367 2 7.0306 2 7.44444 2H16.5556C16.9694 2 17.1763 2 17.342 2.06031C17.6198 2.16141 17.8386 2.38021 17.9397 2.65798C18 2.82367 18 3.0306 18 3.44444V9C18 12.3137 15.3137 15 12 15ZM12 15V18M18 4H20.5C20.9659 4 21.1989 4 21.3827 4.07612C21.6277 4.17761 21.8224 4.37229 21.9239 4.61732C22 4.80109 22 5.03406 22 5.5V6C22 6.92997 22 7.39496 21.8978 7.77646C21.6204 8.81173 20.8117 9.62038 19.7765 9.89778C19.395 10 18.93 10 18 10M6 4H3.5C3.03406 4 2.80109 4 2.61732 4.07612C2.37229 4.17761 2.17761 4.37229 2.07612 4.61732C2 4.80109 2 5.03406 2 5.5V6C2 6.92997 2 7.39496 2.10222 7.77646C2.37962 8.81173 3.18827 9.62038 4.22354 9.89778C4.60504 10 5.07003 10 6 10M7.44444 22H16.5556C16.801 22 17 21.801 17 21.5556C17 19.5919 15.4081 18 13.4444 18H10.5556C8.59188 18 7 19.5919 7 21.5556C7 21.801 7.19898 22 7.44444 22Z"
      stroke="#225CCB"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const Klarna: React.FC<SvgIconProps> = (props) => (
  <svg
    width="28px"
    height="28px"
    style={{
      background: "#F4B6C7",
      padding: 5,
      borderRadius: 10,
    }}
    viewBox="0 0 24 24"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M4.592 2v20H0V2h4.592zm11.46 0c0 4.194-1.583 8.105-4.415 11.068l-.278.283L17.702 22h-5.668l-6.893-9.4 1.779-1.332c2.858-2.14 4.535-5.378 4.637-8.924L11.562 2h4.49zM21.5 17a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5z" />
  </svg>
);


const BlueTick: React.FC<SvgIconProps> = props => (
  <svg
    width={22}
    height={22}
    viewBox='0 0 29 29'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect width={29} height={29} rx={14.5} fill='#D1FADF' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M20.6582 8.9296L12.0065 17.2792L9.71067 14.8263C9.28775 14.4275 8.62317 14.4034 8.13983 14.7417C7.66858 15.0921 7.53567 15.7084 7.82567 16.2038L10.5444 20.6263C10.8103 21.0371 11.2694 21.2909 11.789 21.2909C12.2844 21.2909 12.7557 21.0371 13.0215 20.6263C13.4565 20.0584 21.7578 10.1621 21.7578 10.1621C22.8453 9.05044 21.5282 8.07169 20.6582 8.91752V8.9296Z'
      fill='#12B76A'
    />
  </svg>
);

const IconRender = ({iconName}:any) => {
  switch (iconName) {
    case 'arrow':
      return <ArrowRight />;
    case 'eye':
      return <Eye />;
    case 'blueEye':
      return <BlueEye />;
    case 'layersThree':
      return <LayersThree />;
    case 'activity':
      return <Activity />;
    case 'currencyDollar':
      return <CurrencyDollar />;
      case "klarna":
        return <Klarna />;
    case 'trophy':
      return <Trophy />;
    default:
      return <CurrencyDollar />;
  }
}
export {
  ArrowRight,
  Eye,
  BlueEye,
  LayersThree,
  Activity,
  CurrencyDollar,
  Trophy,
  Klarna,
  IconRender,
  BlueTick
};
