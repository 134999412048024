import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styles from './style.module.scss';
import moment from 'moment';
import { getIconByShopType } from '../../../utils/storeIconsUtlis';
import { getColorByStatus } from '../../../utils/disputeUtils';
import { BlueTick, svgIcons } from '../../../elements';

import { Loading, NoDataFound } from '../../../components';
import FormatCurrency from '../../formatCurrency';

const recentDisputesColumns = [
  'Dispute ID',
  'Store Name',
  'Date',
  'Amount',
  'Processor',
  'Reason',
  'Response Due',
  'Decision Date',
  'Status',
];

const RecentDisputes: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, data, isError, isSuccess } = useSelector(
    (state: any) => state?.dashboard?.recentDisputesData
  );

  const handleDisputeId = (disputeData: any) => {
    navigate(`/dispute/${disputeData?.id}`);
  };

  const handleRecentDisputeStatus = (disputeData: any) => {
    if (
      disputeData?.status &&
      disputeData?.status?.toLowerCase() === 'resolved'
    ) {
      if (disputeData?.disputeOutcome === 'RESOLVED_SELLER_FAVOUR') {
        return 'Won';
      } else if (disputeData?.disputeOutcome === 'RESOLVED_BUYER_FAVOUR') {
        return 'Lost';
      } else {
        return 'Resolved';
      }
    } else {
      return disputeData?.status && disputeData?.status?.split('_').join(' ');
    }
  };

  return (
    <>
      <div className={styles.tableWrapper}>
        {isLoading && <Loading />}
        {!isLoading && isSuccess && data && data?.length > 0 ? (
          <table className={styles.table}>
            <thead>
              <tr>
                {recentDisputesColumns.map((recentDisputesColumn, index) => (
                  <th key={index}>{recentDisputesColumn}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.slice(0, 10).map((recentDispute: any, index: number) => (
                <tr key={index} className={styles.tableDataRow}>
                  <td
                    data-testid='recent-dispute-id'
                    onClick={() => handleDisputeId(recentDispute)}
                  >
                    {/* this is the code for blueTick */}
                   <div style={{display:"flex",alignItems:"center", justifyContent:"start", gap:"4px"}} className={styles.blueTickWrapper}>
                   {recentDispute?.submittedByChargePay && (
                      <div
                        style={{
                          marginRight: '5px',
                          marginTop: '5px',
                        }}
                      >
                        <BlueTick />
                      </div>
                    )}
                    <p className={styles.recentDisputeID}>
                      {recentDispute?.id}
                    </p>
                   </div>
                  </td>
                  <td>{recentDispute?.shopName}</td>
                  <td>
                    {recentDispute?.createTime &&
                      moment(recentDispute?.createTime).format('MMMM DD, YYYY')}
                  </td>
                  <td>
                    <FormatCurrency
                      value={Number(recentDispute?.disputeAmountValue)?.toFixed(
                        2
                      )}
                      currencyCode={recentDispute?.disputeAmountCurrencyCode}
                    />
                    {/* {
                      getDisputeCurrency(
                        recentDispute?.disputeAmountCurrencyCode
                      )?.currencySign
                    }
                    {Number(recentDispute?.disputeAmountValue)
                      ?.toFixed(2)
                      ?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} */}
                  </td>
                  <td className={styles.store}>
                    {recentDispute?.paymentProcessor && (
                      <img
                        src={
                          getIconByShopType(recentDispute?.paymentProcessor)
                            ?.icon
                        }
                        alt='processor'
                      />
                    )}
                    <div className={styles.storeText}>
                      <p className={styles.storeName}>
                        {recentDispute?.paymentProcessor}
                      </p>
                      <p className={styles.storeEmail}>
                        {recentDispute?.storeEmail}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div className={styles.recentDisputeReason}>
                      <p>
                        {recentDispute?.reason &&
                          recentDispute?.reason.split('_').join(' ')}
                      </p>
                    </div>
                  </td>
                  <td>
                    {recentDispute?.sellerResponseDueDate &&
                      moment(recentDispute?.sellerResponseDueDate).format(
                        'MMMM DD, YYYY'
                      )}
                  </td>
                  <td>
                    {recentDispute?.decisionDate &&
                      moment(recentDispute?.decisionDate).format(
                        'MMMM DD, YYYY'
                      )}
                  </td>
                  <td>
                    <div
                      className={styles.recentDisputeStatus}
                      style={{
                        backgroundColor:
                          recentDispute?.status &&
                          getColorByStatus(
                            handleRecentDisputeStatus(recentDispute)
                          )?.backgroundColor,
                      }}
                    >
                      <p>
                        {recentDispute?.status &&
                          handleRecentDisputeStatus(recentDispute)}
                      </p>
                    </div>
                  </td>
                  {/* <td>{recentDispute?.actions && recentDispute?.actions?.split("_").join(" ")}</td> */}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          ((!isLoading && isError) || (isSuccess && data?.length === 0)) && (
            <NoDataFound
              icon={svgIcons?.Coffee_Break}
              isError={isError}
              errorStatement='Oops! Something went wrong. Please try again.'
              notFoundMessage='No disputes'
            />
          )
        )}
      </div>
    </>
  );
};

export default RecentDisputes;
